import React from 'react';
import { CardProps } from '../types/card-props';

const Card = ({ title, info }: CardProps) => {
  return (
    <div>
      <div className='card abc'>
        <div className='card-header card-title'>{title}</div>
        <pre>
          <div className='card-body'>
            <p className='card-text body-text'>{info}</p>
          </div>
        </pre>
      </div>
    </div>
  );
};

export default Card;
