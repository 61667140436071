import React from 'react';

const Home = () => {
  const sendToAnotherPage = () => {
    document.location.href = '/basicprogram';
  };
  return (
    <div className='home'>
      <div className='banner'>
        <div className='content'>
          <h1>Syntax Equivalent</h1>
          <p>
            Expand your knowledge of <br />
            other language's syntax QUICKLY
          </p>
          <div>
            {' '}
            <button
              type='button'
              className='main-button'
              onClick={sendToAnotherPage}
            >
              <span className='span721'></span>Let's get started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
