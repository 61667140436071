import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';

const Navbar1 = () => {
  return (
    <div className='navbar-1'>
      <Navbar
        collapseOnSelect
        expand='lg'
        style={{ backgroundColor: '#343a40' }}
      >
        <Navbar.Brand href='/' className='logoofsyntax'>
          SE
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link href='basicprogram'>BasicProgram</Nav.Link>
            <Nav.Link href='comments'>Comments</Nav.Link>
            <Nav.Link href='input'>Input</Nav.Link>
            <Nav.Link href='output'>Output</Nav.Link>
            <Nav.Link href='datatypes'>Datatypes</Nav.Link>
            <Nav.Link href='variables'>Variables</Nav.Link>
            <Nav.Link href='math'>Math</Nav.Link>
            <Nav.Link href='breakcontinue'>Break/Continue</Nav.Link>
            <Nav.Link href='string'>String</Nav.Link>
            <Nav.Link href='arrays'>Arrays</Nav.Link>
            <Nav.Link href='conditional'>Conditional</Nav.Link>
            <Nav.Link href='loop'>Loop</Nav.Link>
            <Nav.Link href='function'>Function</Nav.Link>
            <Nav.Link href='classandobject'>Class/Object</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navbar1;
