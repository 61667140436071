import React, { useState, useEffect } from 'react';
import Card from '../components/card';
import Navbar1 from '../components/navbar';
import Navbar2 from '../components/navbar2';
import Navbar3 from '../components/navbar3';
import LanguageTitle from '../components/languagetitle';
import ProgressBar from '../components/progressbar';
import BeforeAfterButton from '../components/beforeafterbutton';
import { CardInfo } from '../types/card-props';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { db } from '../firebase/firebase';
const Arrays = () => {
  const [listc, setListc] = useState<CardInfo[]>([]);
  const [listjava, setListjava] = useState<CardInfo[]>([]);
  const [listjavascript, setListjavascript] = useState<CardInfo[]>([]);
  const [listpython, setListpython] = useState<CardInfo[]>([]);
  var count = [0, 0, 0, 0];
  var count1 = [0, 0, 0, 0];
  var count2 = [0, 0, 0, 0];
  var count3 = [0, 0, 0, 0];
  var count4 = [0, 0, 0, 0];
  var count5 = [0, 0, 0, 0];

  const prev = '/string';
  const next = '/conditional';
  const title = 'Arrays';

  useEffect(() => {
    const fetchCollection = (collectionName: string) => {
      const docRef = doc(db, collectionName, 'arrays');
      const collectionRef = collection(docRef, 'arr');
      const q = query(collectionRef, orderBy('id'));

      onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          info: doc.data().info,
          title: doc.data().title,
        }));
        if (collectionName === 'C++') {
          setListc(data);
        } else if (collectionName === 'Java') {
          setListjava(data);
        } else if (collectionName === 'Python') {
          setListpython(data);
        } else {
          setListjavascript(data);
        }
      });
    };

    fetchCollection('C++');
    fetchCollection('Java');
    fetchCollection('Javascript');
    fetchCollection('Python');
  }, []);
  return (
    <div>
      <Navbar1 />
      <Navbar2 />
      <Navbar3 />
      <div className='container-fluid'>
        <LanguageTitle />
        <br />
        <br />
        <BeforeAfterButton prev={prev} next={next} title={title} />
        <br />
        <br />

        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4'>
          <div className='col small-screen  every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listc.length === 0 ? (
              <ProgressBar />
            ) : (
              listc.map((list) => {
                count[0]++;
                if (count[0] === 1) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listjava.length === 0 ? (
              <ProgressBar />
            ) : (
              listjava.map((list) => {
                count[1]++;
                if (count[1] === 1) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listjavascript.length === 0 ? (
              <ProgressBar />
            ) : (
              listjavascript.map((list) => {
                count[2]++;
                if (count[2] === 1) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listpython.length === 0 ? (
              <ProgressBar />
            ) : (
              listpython.map((list) => {
                count[3]++;
                if (count[3] === 1) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
        </div>
        <hr className='hr' />
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4'>
          <div className='col small-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listc.length === 0 ? (
              <ProgressBar />
            ) : (
              listc.map((list) => {
                count1[0]++;
                if (count1[0] === 2) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listjava.length === 0 ? (
              <ProgressBar />
            ) : (
              listjava.map((list) => {
                count1[1]++;
                if (count1[1] === 2) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listjavascript.length === 0 ? (
              <ProgressBar />
            ) : (
              listjavascript.map((list) => {
                count1[2]++;
                if (count1[2] === 2) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listpython.length === 0 ? (
              <ProgressBar />
            ) : (
              listpython.map((list) => {
                count1[3]++;
                if (count1[3] === 2) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
        </div>
        <hr className='hr' />
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4'>
          <div className='col small-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listc.length === 0 ? (
              <ProgressBar />
            ) : (
              listc.map((list) => {
                count2[0]++;
                if (count2[0] === 3) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listjava.length === 0 ? (
              <ProgressBar />
            ) : (
              listjava.map((list) => {
                count2[1]++;
                if (count2[1] === 3) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listjavascript.length === 0 ? (
              <ProgressBar />
            ) : (
              listjavascript.map((list) => {
                count2[2]++;
                if (count2[2] === 3) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listpython.length === 0 ? (
              <ProgressBar />
            ) : (
              listpython.map((list) => {
                count2[3]++;
                if (count2[3] === 3) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
        </div>
        <hr className='hr' />
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4'>
          <div className='col small-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listc.length === 0 ? (
              <ProgressBar />
            ) : (
              listc.map((list) => {
                count3[0]++;
                if (count3[0] === 4) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listjava.length === 0 ? (
              <ProgressBar />
            ) : (
              listjava.map((list) => {
                count3[1]++;
                if (count3[1] === 4) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listjavascript.length === 0 ? (
              <ProgressBar />
            ) : (
              listjavascript.map((list) => {
                count3[2]++;
                if (count3[2] === 4) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listpython.length === 0 ? (
              <ProgressBar />
            ) : (
              listpython.map((list) => {
                count3[3]++;
                if (count3[3] === 4) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
        </div>
        <hr className='hr' />
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4'>
          <div className='col small-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listc.length === 0 ? (
              <ProgressBar />
            ) : (
              listc.map((list) => {
                count4[0]++;
                if (count4[0] === 5) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listjava.length === 0 ? (
              <ProgressBar />
            ) : (
              listjava.map((list) => {
                count4[1]++;
                if (count4[1] === 5) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listjavascript.length === 0 ? (
              <ProgressBar />
            ) : (
              listjavascript.map((list) => {
                count4[2]++;
                if (count4[2] === 5) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listpython.length === 0 ? (
              <ProgressBar />
            ) : (
              listpython.map((list) => {
                count4[3]++;
                if (count4[3] === 5) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
        </div>
        <hr className='hr' />
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4'>
          <div className='col small-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>C++</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listc.length === 0 ? (
              <ProgressBar />
            ) : (
              listc.map((list) => {
                count5[0]++;
                if (count5[0] === 6) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Java</p>
          </div>
          <div className='col'>
            {listjava.length === 0 ? (
              <ProgressBar />
            ) : (
              listjava.map((list) => {
                count5[1]++;
                if (count5[1] === 6) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Javascript</p>
          </div>
          <div className='col medium-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listjavascript.length === 0 ? (
              <ProgressBar />
            ) : (
              listjavascript.map((list) => {
                count5[2]++;
                if (count5[2] === 6) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <div className='col small-screen every-title'>
            <p>Python</p>
          </div>
          <div className='col'>
            {listpython.length === 0 ? (
              <ProgressBar />
            ) : (
              listpython.map((list) => {
                count5[3]++;
                if (count5[3] === 6) {
                  return (
                    <Card key={list.id} title={list.title} info={list.info} />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Arrays;
