import React from 'react';
// import '../css/languagetitle.css';

const LanguageTitle = () => {
  return (
    <div className='row large-screen every-title'>
      <div className='col-lg-3'>C++</div>
      <div className='col-lg-3'>Java</div>
      <div className='col-lg-3'>Javascript</div>
      <div className='col-lg-3'>Python</div>
    </div>
  );
};

export default LanguageTitle;
