import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

var firebaseConfig = {
  apiKey: 'AIzaSyAiyvcLovUn8F55w27LGTZoVFlFeOgRSL8',
  authDomain: 'syntaxequivalent.firebaseapp.com',
  projectId: 'syntaxequivalent',
  storageBucket: 'syntaxequivalent.appspot.com',
  messagingSenderId: '794816714781',
  appId: '1:794816714781:web:65082fea951a9be0864ef4',
  measurementId: 'G-BXW8FWHPMY',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// firebase.analytics();
export const db = getFirestore(app);
