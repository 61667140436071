import React from 'react';
import { BeforeAfterButtonProps } from '../types/card-props';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';

const BeforeAfterButton = (props: BeforeAfterButtonProps) => {
  return (
    <div className='container-fluid'>
      <div className='d-flex justify-content-between'>
        {props.prev ? (
          <a className='beforeafterbutton' href={props.prev} role='button'>
            <FaLongArrowAltLeft />
          </a>
        ) : (
          <div></div>
        )}
        <h2>{props.title}</h2>
        {props.next ? (
          <a className='beforeafterbutton' href={props.next} role='button'>
            <FaLongArrowAltRight />
          </a>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default BeforeAfterButton;
