import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
// import GetInfo from './pages/getinfo';
import Conditional from './pages/conditional';
import Loop from './pages/loop';
import BasicProgram from './pages/basicprogramming';
import Comments from './pages/comments';
import Output from './pages/output';
import Input from './pages/input';
import Datatypes from './pages/datatypes';
import String from './pages/string';
import Variables from './pages/variables';
import Math from './pages/math';
import BreakContinue from './pages/breakcontinue';
import Arrays from './pages/arrays';
import Functions from './pages/functions';
import ClassandObject from './pages/classandobject';
import Home from './pages/home';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path='/45984590' element={<GetInfo />} /> */}
          <Route path='/conditional' element={<Conditional />} />
          <Route path='/loop' element={<Loop />} />
          <Route path='/basicprogram' element={<BasicProgram />} />
          <Route path='/comments' element={<Comments />} />
          <Route path='/output' element={<Output />} />
          <Route path='/input' element={<Input />} />
          <Route path='/datatypes' element={<Datatypes />} />
          <Route path='/string' element={<String />} />
          <Route path='/variables' element={<Variables />} />
          <Route path='/math' element={<Math />} />
          <Route path='/breakcontinue' element={<BreakContinue />} />
          <Route path='/arrays' element={<Arrays />} />
          <Route path='/function' element={<Functions />} />
          <Route path='/classandobject' element={<ClassandObject />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
