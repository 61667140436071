import React from 'react';
import { Spinner } from 'react-bootstrap';

const ProgressBar = () => {
  return (
    <div>
      <Spinner animation='border' role='status'>
        <span className='sr-only'></span>
      </Spinner>
    </div>
  );
};

export default ProgressBar;
